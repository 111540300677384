import * as React from 'react'
import * as css from './Header.module.css'
import MenuToggle from './MenuToggle'
import Logo from './Logo'
import HeaderLinks from './HeaderLinks'
import LoginState from './LoginState'

const Header = () => (
  <header className={css.root}>
    <div className={`${css.inner} container-lg padding-x`}>
      <MenuToggle />
      <Logo />
      <HeaderLinks />
      <LoginState />
    </div>
  </header>
)

export default Header
