import * as React from 'react'
import * as css from './HeaderLinks.module.css'
import { GlobalContext } from 'components/common/GlobalContext'
import { Link } from 'gatsby'
import MegaMenu from './nav/MegaMenu'
import ProductTool from 'components/header/ProductTool'
import SubnavLink from 'components/header/SubnavLink'
import { graphql, useStaticQuery } from 'gatsby'
import { useAuth0 } from '@auth0/auth0-react'
import slugify from 'slugify'
import clsx from 'clsx'

function slug(str) {
  return slugify(str, { lower: true })
}

const HeaderLinks = () => {
  const { allSanityProductPg } = useStaticQuery(query)
  const { $headerMenuOpen, set$megaMenu } = React.useContext(GlobalContext)
  const { isAuthenticated } = useAuth0()

  const filteredProducts = allSanityProductPg.nodes.filter(({ title }) => !['401k'].includes(title))
  const product_401k = allSanityProductPg.nodes.find(({ title }) => title === '401k')

  return (
    <div className={clsx(css.root, $headerMenuOpen && 'is-open', 'no-scrollbar')}>
      <ul className={css.list}>
        {filteredProducts.map(({ title, resources, tools, seo }, key) => (
          <MegaMenu title={title} url={`/${ seo.slug.current }`} key={key}>
            <div className={`${css.parent} richtext`}>
              <h2 className="uppercase">Tools</h2>
              <nav className={css.children}>
                {tools?.ssoLinks?.map((ssoLink, key) => (
                  <ProductTool {...ssoLink} className={`${css.link} link`} key={key} />
                ))}
                <SubnavLink title="Apps & Forms Engine" sso="Apps & Forms Engine" className={css.link} locked />
                <SubnavLink title="Point of Sale Support" directUrl="/point-of-sales-support" className={css.link} />
                <SubnavLink title="My Business Portal" directUrl="/services-tools/my-portal" className={css.link} locked />
                <SubnavLink title="Document Upload" directUrl="/document-upload" className={css.link} locked />
              </nav>
            </div>

            {!!resources?.resources.length && (
              <div className={`${css.parent} richtext`}>
                <h2 className="uppercase">Resources</h2>
                <nav className={css.children}>
                  {resources?.resources.map((resource, key) => (
                    <div key={key}>
                      <Link
                        className={`${css.link} link`}
                        to={`/${ seo.slug.current }#${ slug(resource.title || '') }`}
                        onClick={() => set$megaMenu(false)}
                      >
                        {resource.title}
                      </Link>
                    </div>
                  ))}
                </nav>
              </div>
            )}
          </MegaMenu>
        ))}

        <li><Link className="nav--link" to={`/${product_401k.seo.slug.current}`}>{product_401k.title}</Link></li>

        {!isAuthenticated && <li><Link className="nav--link" to="/why-cps">Why CPS</Link></li>}
      </ul>
    </div>
  )
}

export default HeaderLinks

const query = graphql`query HeaderLinks {
  allSanityProductPg(
    sort: {fields: order}
    filter: {title: {ne: "Advanced Markets"}}
  ) {
    nodes {
      title
      resources { resources { title } }
      tools {
        ssoLinks {
          title
          cta { ...cta }
        }
      }
      seo { slug { current } }
    }
  }
}`
